import React from "react"
import tw, { styled, theme, css } from "twin.macro"
import { SimpleArrow } from "../components/arrow/arrow"
import { Wrapper as SimpleArrowWarapper } from "../components/arrow/arrow.styles"
import { injectFontSize } from "./text"

const commonFormInput = tw`
  block
  w-full
  border
  border-gray-blue
  rounded
  hocus:border-primary
  focus:(
    outline-none 
    ring
    ring-primary
    ring-opacity-20
  )
`

export const FormSelectWrap = styled.div`
  ${tw`relative`}

  ${SimpleArrowWarapper} {
    ${tw`
      absolute
      block
      top-1/2
      bg-no-repeat
      fill-current
    `}
    height: 12px;
    width: 12px;
    right: 20px;
    margin-top: -7px;
  }

  &:hover,
  &:focus {
    ${SimpleArrowWarapper} {
      ${tw`text-primary`}
    }
  }
`

export const StyledFormSelect = styled.select`
  ${commonFormInput}
  appearance: none;
  padding: 10px;
  position: relative;
`

export const FormSelect = ({ children, ...props }) => {
  return (
    <FormSelectWrap>
      <StyledFormSelect {...props}>{children}</StyledFormSelect>
      <SimpleArrow direction="down" />
    </FormSelectWrap>
  )
}

export const FormText = styled.input`
  ${commonFormInput}
  padding: 10px;
`

export const FormTextArea = styled.textarea`
  ${commonFormInput}
  min-height: 200px;
  padding: 10px;
  resize: vertical;
`
export const FormCheckboxWrap = tw.div`
  relative
`

export const FormCheckbox = styled.input`
  ${tw`
    border
    border-gray-blue
    rounded-sm
    appearance-none
    flex-shrink-0
  `}
  height: 20px;
  width: 20px;

  &:checked {
    ${tw`
      border-primary
      bg-primary
    `}
  }

  &:checked::after {
    content: "✔";
    font-size: 15px;
    height: 20px;
    width: 20px;
    ${tw`
      absolute
      text-white
      top-0
      left-0
      flex
      items-center
      justify-center
    `}
  }
`

export const FormCheckboxLabel = styled.label`
  ${props => injectFontSize(props, "s")}

  margin-left: 10px;

  & a {
    ${tw`
      underline
      hover:text-primary
      hover:underline
    `}
  }
`

export const FormRow = styled.div`
  margin-bottom: 10px;

  @media (min-width: ${theme`screens.sm`}) {
    margin-bottom: 15px;
  }
`

export const FormRowCheckbox = styled(FormRow)`
  ${tw`flex`}
`
export const buttonStyle = css`
  ${tw`
    no-underline
    hover:no-underline
    hover:text-white
    text-white
    inline-block
    bg-action
    rounded-full
    font-bold
    hover:bg-action-hover
  `}
  padding: 10px 30px;
`

export const Button = styled.button`
  ${buttonStyle}
`
