import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Wrapper, Copyright, FooterNav, FooterLink } from "./footer.styles"

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Footer = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(query)
  return (
    <Wrapper>
      <FooterNav>
        <Copyright>
          @Copyright {new Date().getFullYear()} {title}
        </Copyright>
        <FooterLink to="/datenschutz">Datenschutz</FooterLink>
        <FooterLink to="/impressum">Impressum</FooterLink>
      </FooterNav>
    </Wrapper>
  )
}

export default Footer
