import tw, { styled } from "twin.macro"
import { css, keyframes } from "styled-components"

const bar1Animation = keyframes`
 0% { transform: translateY(0) }
 50% { transform: translateY(6px) }
 100% { transform: translateY(6px) rotate(45deg) }
`
const bar2Animation = keyframes`
 0% { opacity: 1 }
 50% { opacity: 0 }
 100% { opacity: 0 }
`
const bar3Animation = keyframes`
 0% { transform: translateY(0) }
 50% { transform: translateY(-6px) }
 100% { transform: translateY(-6px) rotate(-45deg) }
`

const bar1OpenCss = css`
  animation-name: ${bar1Animation};
  animation-fill-mode: forwards;
`

const bar2OpenCss = css`
  animation-name: ${bar2Animation};
  animation-fill-mode: forwards;
`
const bar3OpenCss = css`
  animation-name: ${bar3Animation};
  animation-fill-mode: forwards;
`

export const Bar = styled.span`
  ${tw`
    block
    bg-current
  `}
  height: 2px;
  margin: 4px auto;
  width: 20px;
`

export const ToggleButtonWrapper = styled.button`
  ${tw`
    items-center
    flex-row
    justify-center
    relative
  `}

  ${Bar} {
    animation-duration: 0.5s;
    transition: opacity, 0.3s ease, transform 0.3s ease-in-out;

    &:first-child {
      ${({ open }) => (open ? bar1OpenCss : "")}
    }
    &:nth-child(2) {
      ${({ open }) => (open ? bar2OpenCss : "")}
    }
    &:last-child {
      ${({ open }) => (open ? bar3OpenCss : "")}
    }
  }
`
