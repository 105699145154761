import tw, { css, styled } from "twin.macro"
import { Link } from "gatsby"
import { spacingBottom, spacingTop } from "./layout"
import { injectHeadingProps } from "./headings"
import { buttonStyle } from "./form"

export const fontSizeH1 = tw`
  text-h1
  sm:text-h1-sm
  md:text-h1-md
  lg:text-h1-lg
`

export const fontSizeH2 = tw`
  text-h2
  sm:text-h2-sm
  md:text-h2-md
  lg:text-h2-lg
`
export const fontSizeH3 = tw`
  text-h3
  sm:text-h3-sm
  md:text-h3-md
  lg:text-h3-lg
`
export const fontSizeH4 = tw`
  text-h4
  sm:text-h4-sm
  md:text-h4-md
  lg:text-h4-lg
`

export const fontSizeBase = tw`
  text-base
  sm:text-base-sm
  md:text-base-md
  lg:text-base-lg
`

export const fontSizeS = tw`
  text-s
  sm:text-s-sm
  md:text-s-md
  lg:text-s-lg
`

export const fontSizeXs = tw`
  text-xs
  sm:text-xs-sm
  md:text-xs-md
  lg:text-xs-lg
`
export const injectFontSize = ({ size }, defaultSize) => {
  const textSize = size || defaultSize
  return css`
    ${textSize === "h1"
      ? fontSizeH1
      : textSize === "h2"
      ? fontSizeH2
      : textSize === "h3"
      ? fontSizeH3
      : textSize === "h4"
      ? fontSizeH4
      : textSize === "xs"
      ? fontSizeS
      : textSize === "s"
      ? fontSizeS
      : fontSizeBase}
  `
}

export const injectFontWeight = ({ fontWeight }, defaultFontWeight) => {
  const weight = fontWeight || defaultFontWeight
  return css`
    ${weight === "bold"
      ? tw`font-bold`
      : weight === "extra-bold"
      ? tw`font-extra-bold`
      : weight === "normal"
      ? tw`font-normal`
      : tw`font-light`}
  `
}

export const injectColor = (props, defaultColor) => {
  const color = props.color || defaultColor
  return css`
    ${color === "primary"
      ? tw`text-primary`
      : color === "white"
      ? tw`text-white`
      : color === "gray"
      ? tw`text-gray`
      : color === "action"
      ? tw`text-action`
      : ""}
  `
}

export const P = styled.p`
  ${props => injectColor(props)}
  ${props => injectFontSize(props)}
  ${props => injectFontWeight(props)}
`

export const Bullets = styled.div`
  & li {
    ${tw`
      font-bold
      text-gray
      relative
    `}

    margin-bottom: 10px;
    padding-left: 30px;

    &:before {
      content: "";
      height: 9px;
      left: 7px;
      top: 7px;
      width: 9px;

      ${tw`
        bg-action
        absolute
        rounded-xl
        ring
        ring-primary
        ring-offset-4
      `}
    }
  }
`

export const LongText = styled.div`
  ${fontSizeBase}
  ${tw`lg:text-base-md`}

  h1 {
    ${props => injectHeadingProps(props, "h2", "extra-bold", "primary")}
    ${spacingBottom("margin", "m")}
  }

  h2 {
    ${fontSizeH3}
    ${spacingTop("margin", "m")}
    ${spacingBottom("margin", "xxs")}

    & + h3 {
      ${tw`mt-0`}
    }
  }

  h3 {
    ${fontSizeBase}
    ${spacingBottom("margin", "xxs")}
    ${spacingTop("margin", "s")}
  }

  p {
    ${spacingBottom("margin", "xxs")}
  }

  ul {
    ${spacingBottom("margin", "xs")}
    ${tw`
      list-none
      list-outside
    `}
  }

  & li {
    margin-bottom: 0.25em;
    margin-left: 1.5em;
    &:before {
      content: "\\2022";
      width: 1em;
      margin-left: -1em;

      ${tw`
        inline-block
        font-bold
        text-primary
        transform
        scale-125
      `}
    }
  }
`

export const ButtonLink = styled(Link)`
  ${buttonStyle}
`
