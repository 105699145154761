import React from "react"

import { Wrapper } from "./arrow.styles"
const Arrow = props => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        <circle cx="25" cy="25" r="25" />
        <path
          d="M3.375,18A14.625,14.625,0,1,0,18,3.375,14.623,14.623,0,0,0,3.375,18ZM23.7,14.948a1.362,1.362,0,0,1,1.92,0,1.34,1.34,0,0,1,.394.956,1.364,1.364,0,0,1-.4.963l-6.63,6.609a1.355,1.355,0,0,1-1.87-.042l-6.729-6.708a1.357,1.357,0,0,1,1.92-1.92l5.7,5.759Z"
          transform="translate(7 7)"
        />
      </svg>
    </Wrapper>
  )
}

export const SimpleArrow = props => {
  return (
    <Wrapper {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
      >
        <path
          d="M52.773,16.09a4.25,4.25,0,0,1,5.988,0,4.182,4.182,0,0,1,1.228,2.983,4.255,4.255,0,0,1-1.25,3.005l-6.409,6.389L38.055,42.7a4.228,4.228,0,0,1-5.835-.132L11.229,21.64a4.234,4.234,0,1,1,5.988-5.988L35.006,33.616Z"
          transform="translate(-9.988 -4.104)"
        />
      </svg>
    </Wrapper>
  )
}

export default Arrow
