import React, { useState, useRef, useEffect, useCallback } from "react"
import "twin.macro"

import Nav from "../nav/nav"
import { Wrapper, LogoLink, HeaderContainer } from "./header.styles"
import Logo from "../logo/logo"

const Header = ({ pathname, ...rest }) => {
  const [isHidden, setIsHidden] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const isScrolledRef = useRef(false)
  const navIsOpen = useRef(false)
  const lastScroll = useRef(0)
  const mouseOverHeader = useRef(false)
  const refIsHidden = useRef(false)

  const handleScroll = useCallback(() => {
    const top = window.pageYOffset
    const scrollDist = lastScroll.current - top
    if (scrollDist === 0) {
      return
    }

    if (!isScrolledRef.current && top > 50) {
      setIsScrolled(true)
      isScrolledRef.current = true
    } else if (isScrolledRef.current && top < 50) {
      setIsScrolled(false)
      isScrolledRef.current = false
    }

    lastScroll.current = top
    const shouldHide =
      !mouseOverHeader.current &&
      !navIsOpen.current &&
      scrollDist < 0 &&
      top > 200

    if (!refIsHidden.current && shouldHide) {
      refIsHidden.current = true
      setIsHidden(true)
    } else if (refIsHidden && !shouldHide) {
      refIsHidden.current = false
      setIsHidden(false)
    }
  }, [])

  const notifyNavOpen = flag => {
    navIsOpen.current = flag
  }

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("scroll", handleScroll, { passive: true })

      return () => {
        window.removeEventListener("scroll", handleScroll, {
          passive: true,
        })
      }
    }
  }, [handleScroll])

  return (
    <Wrapper
      hide={isHidden}
      isScrolled={isScrolled}
      onMouseEnter={() => {
        mouseOverHeader.current = true
      }}
      onMouseLeave={() => {
        mouseOverHeader.current = false
      }}
      {...rest}
    >
      <HeaderContainer>
        <LogoLink to="/">
          <Logo />
        </LogoLink>
        <Nav pathname={pathname} notifyOpen={notifyNavOpen} />
      </HeaderContainer>
    </Wrapper>
  )
}

export default Header
