import React from "react"

import { ToggleButtonWrapper, Bar } from "./toggle-button.styles"

const ToggleButton = ({ open = false, ...rest }) => {
  return (
    <ToggleButtonWrapper
      aria-expanded="false"
      aria-haspopup="true"
      aria-controls="mobile-menu"
      aria-label="Menü"
      role="button"
      type="button"
      open={open}
      {...rest}
    >
      <Bar />
      <Bar />
      <Bar />
    </ToggleButtonWrapper>
  )
}

export default ToggleButton
