import tw, { styled, css } from "twin.macro"

import { injectSpacing, injectLayout } from "./layout"

export const Main = styled.main`
  ${props =>
    props.hasHero
      ? ""
      : tw`
      pt-header-height-xs
      lg:pt-header-height-lg
    `}
  min-height: calc(100vh - 34px);
`

export const Container = styled.div`
  ${props => injectSpacing(props)}
  ${props => injectLayout(props)}
`
export const Section = styled.section`
  ${props => injectSpacing(props)}
  ${props => injectLayout(props)}
`
export const Article = styled.article`
  ${props => injectSpacing(props)}
  ${props => injectLayout(props)}
`

export const Header = styled.header`
  ${props => injectSpacing(props)}
  ${props => injectLayout(props)}
`

export const TextContainer = styled.div`
  ${props => injectSpacing(props)}
  ${props => injectLayout(props)}
  ${tw`
    mx-auto
    max-w-text
  `}
`

export const PageSection = styled(Container)``

const grid2ColumnsXs = tw`
  grid-cols-2
`

const grid2ColumnsSm = tw`
  sm:grid-cols-2
`

const grid2ColumnsMd = tw`
  md:grid-cols-2
`

const grid2ColumnsLg = tw`
  lg:grid-cols-2
`

const grid2Columns = props => {
  const breakPoint = props.breakPoint || "xs"
  return css`
    ${breakPoint === "xs"
      ? grid2ColumnsXs
      : breakPoint === "sm"
      ? grid2ColumnsSm
      : breakPoint === "md"
      ? grid2ColumnsMd
      : breakPoint === "lg"
      ? grid2ColumnsLg
      : ""}
  `
}

const grid3ColumnsXs = tw`
  grid-cols-3
`
const grid3ColumnsSm = tw`
  sm:grid-cols-3
`
const grid3ColumnsMd = tw`
  md:grid-cols-3
`
const grid3ColumnsLg = tw`
  lg:grid-cols-3
`

const grid3Columns = props => {
  const breakPoint = props.breakPoint || "xs"
  return css`
    ${breakPoint === "xs"
      ? grid3ColumnsXs
      : breakPoint === "sm"
      ? grid3ColumnsSm
      : breakPoint === "md"
      ? grid3ColumnsMd
      : breakPoint === "lg"
      ? grid3ColumnsLg
      : ""}
  `
}

export const Grid = styled(Container)`
  ${tw`
    grid
    grid-cols-1
    gap-xs
    sm:gap-sm
    md:gap-md
    lg:gap-lg
  `}

  ${props => (props.columns === 2 ? grid2Columns(props) : "")}
  ${props => (props.columns === 3 ? grid3Columns(props) : "")}
`
