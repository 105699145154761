import tw, { styled } from "twin.macro"

import { injectColor } from "../../styles/text"

export const Wrapper = styled.button`
  ${props => injectColor(props, "gray-dark")}
  ${tw`
    h-full
    w-full
    transform
    focus:outline-none
  `}
  ${props =>
    props.direction === "right"
      ? tw`-rotate-90`
      : props.direction === "up"
      ? tw`rotate-180`
      : props.direction === "left"
      ? tw`rotate-90`
      : ""}

  & svg {
    ${tw`
      block
      h-full
      w-full
    `}
  }

  & circle {
    ${tw`
      fill-current
      text-white
    `}
  }

  & path {
    ${tw`
      fill-current
    `}
  }
`
