import tw, { styled, css, theme } from "twin.macro"
import { Link } from "gatsby"

import { NavList } from "../nav/nav.styles"
import { ToggleButtonWrapper } from "../nav/toggle-button.styles"

const hiddenCss = css`
  transform: translateY(-60px);
`

const negCss = css`
  ${tw`
    bg-transparent
    text-white
  `}

  transition: background-color 0.3s ease, transform 0.2s ease-in-out;

  ${NavList} {
    ${tw`
      text-primary
      navbreak:text-white
    `}
  }

  // shadow
  &::after {
    ${tw`
      opacity-0
    `}
  }
`

const posCss = css`
  ${tw`
    bg-white
    bg-opacity-95
    text-primary
  `}

  ${NavList} {
    ${tw`
      text-primary
      navbreak:text-gray-dark
    `}
  }

  ${ToggleButtonWrapper} {
    ${tw`
      text-gray-dark
    `}
  }

  // shadow
  &::after {
    ${tw`
      opacity-20
    `}
  }
`
export const Wrapper = styled.header`
  ${tw`
    fixed
    h-header-height-xs
    w-full
    z-10
    lg:h-header-height-lg
  `}

  padding: 6px 0;
  transition: background-color 0.3s ease, transform 0.2s ease-in-out;

  @media (min-width: ${theme`screens.lg`}) {
    padding: 10px 0;
  }

  // shadow
  &::after {
    content: "";
    height: 3px;
    mix-blend-mode: multiply;

    ${tw`
      absolute
      top-full
      left-0
      w-full
      bg-gradient-to-b from-gray-dark
      pointer-events-none
      transition-opacity
      duration-300
      ease-out
      `}
  }

  ${props => (props.hide ? hiddenCss : "")};
  ${props =>
    props.headerStyle === "negative" && !props.isScrolled ? negCss : posCss};
`

export const HeaderContainer = styled.div`
  ${tw`
    flex
    justify-between 
    h-full
    mx-auto
  `}

  max-width: 1200px;
  padding: 0 15px;

  @media (min-width: ${theme`screens.sm`}) {
    padding: 0 20px;
  }

  @media (min-width: ${theme`screens.md`}) {
    padding: 0 25px;
  }
`

export const LogoLink = styled(Link)`
  ${tw`
    block
    h-full
  `}
  max-width: 230px;
  width: 70%;
`
