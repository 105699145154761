import tw, { styled, css, theme } from "twin.macro"
import { AnchorLink as GatsbyAnchorLink } from "gatsby-plugin-anchor-links"
import ToggleButton from "./toggle-button"

export const NavListItem = styled.li`
  ${tw`
    text-right

    navbreak:(
      text-center
    )
  `}

  margin-right: 15px;
  margin-top: 10px;

  @media (min-width: ${theme`screens.navbreak`}) {
    margin-left: 15px;
    margin-top: 0;
  }
`
export const AnchorLink = styled(GatsbyAnchorLink)`
  ${tw`
    relative
    font-normal
    inline-block
    text-current
    bg-white
    text-right
    font-extra-bold
    uppercase
    rounded
    text-s
    transform
    transition
    duration-300
    ease-in-out
    scale-95
    hover:scale-100

    navbreak:(
      bg-transparent
      block
      text-s
      text-center
      p-0
      opacity-90
      scale-100
    )

  `}

  box-shadow: 0 0 14px rgb(0 0 0 / 20%);
  padding: 3px 10px;
  letter-spacing: 0.05em;
  /* transform: scale(0.95); */
  transform-origin: right 50%;

  @media (min-width: ${theme`screens.navbreak`}) {
    box-shadow: none;
    margin-top: 5px;
  }

  &:hover,
  &.active {
    ${tw`
      text-action
      navbreak:(
        bg-transparent
      )
    `}
  }
`

export const Burger = styled(ToggleButton)`
  ${tw`
    navbreak:hidden
    absolute
    top-0
    right-0
    z-10
    hocus:outline-none
  `}

  height: 50px;
  width: 50px;
`

const navOpenCss = css`
  ${tw`
    pointer-events-auto
  `}

  ${AnchorLink} {
    // transform: translateX(0);
    opacity: 1;

    ${tw`
      navbreak:(
        opacity-90
      )
  `}
  }
`
const navClosedCss = css`
  ${tw`
    pointer-events-none
  `}

  ${AnchorLink} {
    transform: translateX(30px);
    ${tw`
      opacity-0
      navbreak:(
        transform-none
        opacity-90
        pointer-events-auto
      )
    `}
  }
`

export const NavList = styled.ul`
  ${tw`
    absolute
    top-full
    right-0
    max-h-screen

    navbreak:(
      relative
      flex
      h-full
      top-auto
      items-center
      mt-0
    )
  `}
  margin-top: 10px;
  ${props => (props.isOpen ? navOpenCss : navClosedCss)}
`
