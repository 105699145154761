import tw, { theme, css } from "twin.macro"

export const cssBoxed = tw`
  mx-auto
  max-w-boxed
`
export const cssFluid = tw`
  box-content
  px-outer-margin-xs
  sm:px-outer-margin-sm
  md:px-outer-margin-md
  lg:px-outer-margin-lg
`

export const spacing = (where, paddingOrMargin = "margin", size = true) => {
  const factor =
    size === "xxs"
      ? 0.15
      : size === "xs"
      ? 0.25
      : size === "s"
      ? 0.5
      : size === "m"
      ? 1
      : size === "l"
      ? 1.5
      : size === "xl"
      ? 2
      : 1

  return css`
    ${paddingOrMargin}-${where}: ${2 * factor}rem;

    @media (min-width: ${theme`screens.sm`}) {
      ${paddingOrMargin}${"-"}${where}: ${3 * factor}rem;
    }

    @media (min-width: ${theme`screens.md`}) {
      ${paddingOrMargin}-${where}: ${3.5 * factor}rem;
    }

    @media (min-width: ${theme`screens.lg`}) {
      ${paddingOrMargin}-${where}: ${4 * factor}rem;
    }
  `
}

export const spacingTop = (paddingOrMargin = "margin", size = true) => {
  return spacing("top", paddingOrMargin, size)
}

export const spacingBottom = (paddingOrMargin = "margin", size = true) => {
  return spacing("bottom", paddingOrMargin, size)
}

export const spacingLeft = (paddingOrMargin = "margin", size = true) => {
  return spacing("left", paddingOrMargin, size)
}
export const spacingRight = (paddingOrMargin = "margin", size = true) => {
  return spacing("right", paddingOrMargin, size)
}

export const injectSpacing = props => {
  return css`
    ${props => (props.marginTop ? spacingTop("margin", props.spacingTop) : "")}
    ${props =>
      props.marginBottom ? spacingBottom("margin", props.marginBottom) : ""} 
    ${props =>
      props.marginLeft ? spacingLeft("margin", props.marginLeft) : ""} 
    ${props =>
      props.marginRight ? spacingRight("margin", props.marginRight) : ""} 
    ${props =>
      props.paddingTop ? spacingTop("padding", props.paddingTop) : ""} 
    ${props =>
      props.paddingBottom ? spacingBottom("padding", props.paddingBottom) : ""} 
    ${props =>
      props.paddingLeft ? spacingLeft("padding", props.paddingLeft) : ""} 
    ${props =>
      props.paddingRight ? spacingRight("padding", props.paddingRight) : ""}
  `
}

export const injectLayout = props => {
  return css`
    ${props => (props.boxed ? cssBoxed : "")}
    ${props => (props.fluid ? cssFluid : "")}
    ${props => (props.center ? tw`text-center` : "")}
  `
}
