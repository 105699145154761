import React from "react"
import PropTypes from "prop-types"

import { Main } from "../styles/container"
import Header from "./header/header"
import Footer from "./footer/footer"
import GlobalStyles from "../styles/global.styles"

const Layout = ({ hasHero, headerStyle, children }) => {
  return (
    <>
      <GlobalStyles />
      <Header headerStyle={headerStyle} />
      <Main hasHero={hasHero}>{children}</Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
