import React, { useState } from "react"

import { NavList, NavListItem, AnchorLink, Burger } from "./nav.styles"

const links = [
  {
    name: "tool",
    title: "Tool",
  },
  {
    name: "leistungen",
    title: "Leistungen",
  },
  {
    name: "angebot",
    title: "Angebot",
  },
  {
    name: "kontakt",
    title: "Kontakt",
  },
]

const Nav = ({ notifyOpen }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onToggle = () => {
    setIsOpen(prevIsOpen => {
      const newIsOpen = !prevIsOpen
      notifyOpen && notifyOpen(newIsOpen)
      return newIsOpen
    })
  }
  return (
    <nav>
      <Burger onClick={onToggle} open={isOpen} />
      <NavList isOpen={isOpen}>
        {links &&
          links.map(({ name, title, page = "/" }, index) => (
            <NavListItem key={index}>
              <AnchorLink
                activeClass="active"
                offset={-75}
                duration={500}
                to={`${page}#${name}`}
                title={title}
                onAnchorLinkClick={() => setIsOpen(false)}
              >
                {title}
              </AnchorLink>
            </NavListItem>
          ))}
      </NavList>
    </nav>
  )
}

export default Nav
