import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { cssFluid } from "../../styles/layout"
import { fontSizeXs } from "../../styles/text"
export const Wrapper = styled.footer`
  ${cssFluid}
  ${fontSizeXs}
  ${tw`
    bg-primary
    border
    border-t
    border-primary
    text-center
    text-white
  `}

  padding-top: 5px;
  padding-bottom: 5px;
`

export const FooterNav = tw.nav`
  flex
  flex-wrap
  gap-xs
  justify-center
`

export const Copyright = styled.span``

export const FooterLink = tw(Link)`
  text-white
  no-underline
  hover:underline
  hover:text-white
`
