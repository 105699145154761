import { css, styled } from "twin.macro"
import { injectSpacing, injectLayout } from "./layout"
import { injectFontSize, injectColor, injectFontWeight } from "./text"

export const injectHeadingProps = (
  props,
  defaultSize,
  defaultFontWeight,
  defaultColor
) => {
  return css`
    ${props => injectSpacing(props)}
    ${props => injectLayout(props)}
    ${props => injectColor(props, defaultColor)}
    ${props => injectFontSize(props, defaultSize)}
    ${props => injectFontWeight(props, defaultFontWeight)}
  `
}

const headingStyleH1 = props => {
  return css`
    ${injectHeadingProps(props, "h1", "extra-bold", "primary")}
    margin-bottom: 5px;
  `
}

const headingStyleH2 = props => {
  return css`
    margin-bottom: 5px;
    ${injectHeadingProps(props, "h2", "extra-bold", "primary")}
  `
}

const headingStyleH3 = props => {
  return css`
    margin-bottom: 5px;
    ${injectHeadingProps(props, "h3", "bold", "gray-dark")}
  `
}

const headingStyleH4 = props => {
  return css`
    margin-bottom: 10px;
    ${injectHeadingProps(props, "h4", "bold", "gray-dark")}
  `
}

const injectHeadingStyle = (props, defaultHeadingStyle) => {
  const headingStyle = props.headingStyle || defaultHeadingStyle
  return css`
    ${headingStyle === "h1"
      ? headingStyleH1(props)
      : headingStyle === "h2"
      ? headingStyleH2(props)
      : headingStyle === "h3"
      ? headingStyleH3(props)
      : headingStyle === "h4"
      ? headingStyleH4(props)
      : ""}
  `
}

export const H1 = styled.h1`
  ${props => injectHeadingStyle(props, "h1")}
`

export const H2 = styled.h2`
  ${props => injectHeadingStyle(props, "h2")}
`

export const H3 = styled.h3`
  ${props => injectHeadingStyle(props, "h3")}
`

export const H4 = styled.h4`
  ${props => injectHeadingStyle(props, "h4")}
`
