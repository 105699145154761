import React from "react"
import tw, { GlobalStyles as BaseStyles } from "twin.macro"
import { createGlobalStyle } from "styled-components"

import { fontSizeBase } from "./text"

const CustomStyles = createGlobalStyle`
  body {
    ${tw`
      font-sans
      font-light
      text-gray-dark
    `}
    ${fontSizeBase}
  }

  a {
    ${tw`
      hover:text-primary
      hover:underline
    `}
  }

  p {
    margin-bottom: 0.75rem;
  }
`

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
)

export default GlobalStyles
